import axios from '@axios'

export default {
  async createVereinbarung(dosID) {
    try {
      const r = await axios.get('vereinbarung/getVereinbarungCreate', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async updateVereinbarung(dosID, refreshUnterhalt, refreshVA, refreshGA) {
    try {
      const r = await axios.get('vereinbarung/getVereinbarungUpdate', {
        params: {
          dosID,
          refreshUnterhalt,
          refreshVA,
          refreshGA,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async getVereinbarungDatei(dosID, istWord) { // Download...
    try {
      const r = await axios.get('vereinbarung/getVereinbarungDatei', {
        responseType: 'blob',
        headers: { Accept: istWord ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/pdf' },
        params: {
          dosID,
          istWord,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getVereinbarungSFDT(dosID) { // Download sfdt...
    try {
      const r = await axios.get('vereinbarung/getVereinbarungSFDT', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postVereinbarungSFDT(formData, dosID) {
    try {
      const r = await axios.post('vereinbarung/postDossierVereinbarung', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postVereinbarung(datei, dosID) {
    const formData = new FormData()
    try {
      formData.append('vereinbarung', datei)
      const r = await axios.post('vereinbarung/postDossierVereinbarung', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
}
